html {
    font-size: 1rem;
}

#main {
    padding-top: 60px;
}

@font-face {
    font-family: "SBL Hebrew";
    src: url("fonts/sbl_hebrew.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "SBL Greek";
    src: url("fonts/sbl_greek.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

.text-xx-small {
    font-size: 0.6rem;
}

.text-x-small {
    font-size: 0.8rem;
}

.text-small {
    font-size: 1rem;
}

.text-medium {
    font-size: 1.2rem;
}

.text-large {
    font-size: 1.4rem;
}

.text-x-large {
    font-size: 1.6rem;
}

.text-xx-large {
    font-size: 1.8rem;
}

.biblical-text-hebrew {
    font-family: "SBL Hebrew";
    font-weight: normal;
    font-style: normal;
}

.footer-padding {
    margin-top: 4rem;
}

.biblical-text-greek {
    font-family: "SBL Greek";
    font-weight: normal;
    font-style: normal;
}

.biblical-text-english {
    font-family: "Times";
    font-weight: normal;
    font-style: normal;
}

.body-link {
    color: rgb(33, 37, 41);
    text-decoration: none;
}

.module-license {
    white-space: pre-wrap;
    word-wrap: break-word;
}

.scripture-text {
    white-space: pre-wrap;
    padding-bottom: 1rem;
}

.editor-popupmenu {
    padding: 8px 7px 6px;
    position: absolute;
    z-index: 1;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    opacity: 0;
    transition: opacity 0.75s;
    border-radius: 2px;
}

.editor-para {
    border: 1px solid gray;
    margin: -1px;
}

.sponsors img {
    width: 100%;
}

.team img {
    max-height: 150px;
    max-width: 150px;
}

.toolbar-dropdown {
    background-color: rgb(108, 117, 125);
    list-style: none;
    padding-top: 6px;
}

.toolbar-dropdown:hover {
    background-color: rgb(92, 99, 106);
}

.toolbar-dropdown > a {
    color: white;
    padding-left: 8px;
    padding-right: 8px;
}

.toolbar-dropdown > a:hover {
    color: white;
}

.sponsors a {
    text-decoration: none;
}

.scripture-word {
    cursor: pointer;
}
